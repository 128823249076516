$prefix-path: '../../../../dist' !default;
$vud-icons-path: '~node_modules/@vismaux/vud-icons/dist/img/vismaicons' !default;
$vud-units: 'rem' !default;

@import '../node_modules/@vismaux/vud/src/scss/vud.light-dark.mode';
@import '../node_modules/@vismaux/vud-icons/src/scss/vud-icons.light-dark.mode';

/* text color variables */

@mixin light-mode-var {
    --text-grant: var(--custom-text-grant, var(--green-60));
    --text-revoke: var(--custom-text-revoke, var(--red-60));
    --text-ignore: var(--custom-text-ignore, var(--neutral-60));
    --text-tab-disabled: var(--custom-text-tab-disabled, var(--neutral-40));
    --rows-separator: var(--custom-rows-separator, var(--neutral-30));
    --accent-color: var(--custom-accent-color, var(--neutral-60));
    --app-title-color: var(--custom-app-title-color, var(--neutral-80));
    --column-color-neutral-80: var(--custom-column-color-neutral-80, var(--neutral-80));
}

@mixin dark-mode-var {
    --text-grant: var(--custom-dark-text-grant, var(--green-40));
    --text-revoke: var(--custom-dark-text-revoke, var(--red-40));
    --text-ignore: var(--custom-dark-text-ignore, var(--neutral-40));
    --text-tab-disabled: var(--custom-text-tab-disabled, var(--neutral-60));
    --rows-separator: var(--custom-rows-separator, var(--neutral-60));
    --accent-color: var(--custom-accent-color, var(--neutral-40));
    --app-title-color: var(--custom-app-title-color, var(--neutral-10));
    --column-color-neutral-80: var(--custom-column-color-neutral-80, var(--neutral-10));
}

:root {
    @include light-mode-var();
}

/* Dark Mode */
html[data-theme='dark'],
html.dark-theme {
    @include dark-mode-var();
}


/* Styles for users who prefer dark mode at the OS level */
@media (prefers-color-scheme: dark) {
    html:not([data-theme='light']):not(.light-theme) {
        @include dark-mode-var();
    }
}

/* mixins */

@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

body {
    padding-bottom: rem(5px);
    padding-top: rem(90px);
    font-size: $font-size-xs;
}

.tooltip::before {
    background-image: none;
}

input.form-control {
    font-size: $font-size-xs;
}

input.form-control.read-only,
input.form-control.read-only:hover,
input.form-control.read-only:active,
input.form-control.read-only:focus {
    color: var(--input-text-color);
    border: 0.1rem solid var(--input-border-color);
    background-color: var(--input-bg);
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}


footer {
    height: 0;
    padding: 0;
}

/* new icons */

.vismaicon-sm.vismaicon-filter-active::before {
    -webkit-mask-image: url(assets/images/vismaicons/16_filter_active.svg);
    mask-image: url(assets/images/vismaicons/16_filter_active.svg);
}

.vismaicon-sm.vismaicon-broom::before {
    -webkit-mask-image: url(assets/images/vismaicons/16_broom.svg);
    mask-image: url(assets/images/vismaicons/16_broom.svg);
}

.vismaicon-sm.vismaicon-import::before {
    -webkit-mask-image: url(assets/images/vismaicons/16_import.svg);
    mask-image: url(assets/images/vismaicons/16_import.svg);
}

.vismaicon-sm.vismaicon-menu-more::before {
    -webkit-mask-image: url(assets/images/vismaicons/16_menu_more.svg);
    mask-image: url(assets/images/vismaicons/16_menu_more.svg);
}

.vismaicon.vismaicon-copy::before {
    -webkit-mask-image: url(assets/images/vismaicons/24_copy.svg);
    mask-image: url(assets/images/vismaicons/24_copy.svg);
}

.vismaicon.vismaicon-toogle-left::before {
    -webkit-mask-image: url(assets/images/vismaicons/16_toggle_left.svg);
    mask-image: url(assets/images/vismaicons/16_toggle_left.svg);
}

.vismaicon.vismaicon-toogle-right::before {
    -webkit-mask-image: url(assets/images/vismaicons/16_toggle_right.svg);
    mask-image: url(assets/images/vismaicons/16_toggle_right.svg);
}

.vismaicon-rotate::before {
    transform: rotate(45deg);
}

.form-horizontal {
    .form-group {
        input::placeholder {
            font-style: italic;
        }

        input::-webkit-input-placeholder {
            font-style: italic;
        }

        input:focus::placeholder {
            color: transparent;
        }

        .vismaicon.vismaicon-filled {
            display: none;

            &.in {
                display: block;

                &:hover+.tooltip.left,
                &:focus+.tooltip.left {
                    display: block;
                    inset: calc(-50% + 6.5px) 0px auto auto !important;

                    &.tooltip-warning {
                        inset: -50% 0px auto auto !important;
                    }
                }
            }
        }


        &.has-error .vismaicon.vismaicon-filled,
        &.has-warning .vismaicon.vismaicon-filled {
            position: absolute;
            top: 50%;
            right: -2rem;
            transform: translateY(-0.8rem);
            cursor: pointer;
        }

        &.has-copy-icon div {
            display: inline-block;
            white-space: nowrap;

            input {
                display: inline-block;
            }

            span {
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }

    .checkbox {
        >label {
            >.vismaicon-filled {
                margin-left: rem(4px);
                top: rem(4px);
            }
        }
    }
}

.btn>.vismaicon,
a>.vismaicon,
.vismaicon.vertical-middle {
    vertical-align: middle;
}

.navbar .context-autocomplete {
    overflow: visible;
}

.navbar-default .navbar-header .navbar-brand .dropdown-menu,
.navbar-secondary .navbar-header .navbar-brand .dropdown-menu {
    max-height: calc(100vh - 74px);
    overflow-y: auto;
}

.nc-combobox-element {
    display: block;
    position: relative;

    >.nc-combobox {
        width: 100%;
    }

    &:disabled .nc-combobox:hover,
    &[disabled] .nc-combobox:hover,
    &.disabled .nc-combobox,
    &.disabled .nc-combobox:hover {
        border-color: var(--input-disabled-border-color);
        box-shadow: none;
    }
}

.navbar-default,
.navbar-primary,
.navbar-secondary {
    .navbar-collapse {
        .first-level.navbar-right {
            .dropdown.user-dropdown {
                margin-left: $spacing-0;

                >.dropdown-toggle {
                    >small {
                        max-width: rem(280px);
                    }

                    >.caret {
                        right: rem(20px);
                    }

                    .dropdown-menu {
                        width: 100%;
                    }
                }

                .dropdown-menu {
                    min-width: rem(350px);
                    max-width: rem(600px);
                    width: 100%;


                    .combobox-dropdown {
                        width: 100%;
                    }

                    .p-autocomplete-items {
                        width: 100%;
                        max-height: rem(300px);
                    }
                }

                li.admin-context-dropdown {

                    div.nc-admin-context-dropdown {
                        background: var(--blue-50);
                        width: calc(100% - rem(32px));
                        color: var(--neutral-05);
                    }

                    span.p-dropdown-label {
                        cursor: pointer;
                        height: rem(24px);
                        padding: rem(3px) rem(16px) rem(4px);
                        margin: 0 rem(8px) rem(8px) 0;
                        max-width: 100%;
                        min-width: rem(128px);
                    }

                    .p-dropdown-trigger {
                        padding-right: rem(16px);

                        .p-icon-wrapper {
                            height: rem(12px);
                            width: rem(12px);
                            mask-position: 0 0;
                            mask-repeat: no-repeat;
                            mask-size: cover;
                            background-color: var(--neutral-05);
                        }

                        &[aria-expanded="false"] .p-icon-wrapper {
                            mask-image: url(assets/images/vismaicons/arrows_down_large.svg);
                        }

                        &[aria-expanded="true"] .p-icon-wrapper {
                            mask-image: url(assets/images/vismaicons/arrows_up_large.svg);
                        }
                    }
                }

                .context-autocomplete {

                    >.nc-combobox-element {
                        padding: 0 rem(18px) 0 rem(14px);
                    }
                }

                .user-details-area {
                    >div {
                        .user-text {
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }
}

.admin-ui-page .navbar-default .navbar-header .navbar-brand>a {
    min-width: rem(334px);

    &>img {
        padding-right: rem(10px);
    }
}

.navbar-default.navbar.is-mobile .navbar-collapse .first-level .menudrop.open>.dropdown-menu .navbar-brand.is-open .dropped-apps-toggle>.caret {
    top: rem(-2px) !important;
}

.p-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    >input {
        transform: scale(0);
    }
}

.nc-combobox {
    @extend .combobox;

    .p-autocomplete-dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        width: rem(30px);
        height: rem(30px);
        margin: 0;
        padding: 0;
        outline: none;
        border-radius: 0;
        border: rem(1px) solid transparent;
        background: transparent;
        box-shadow: none;

        top: rem(1px);
        right: rem(1px);

        .vismaicon {
            top: 0;
        }

        &:focus {
            outline: none;
            border-color: var(--input-focus-border-color);
            box-shadow: 0 0 0 rem(1px) var(--input-focus-border-color);
        }

        &:disabled,
        &[disabled] {
            cursor: default;
            pointer-events: none;

            .vismaicon::before {
                background-color: var(--icon-disabled-bg);
            }
        }

    }

    .p-autocomplete-input {

        &,
        &:hover,
        &:focus,
        &:disabled,
        &.disabled {
            border: 0;
            background-color: transparent;
            box-shadow: none;
            font-size: $font-size-xs;
        }
    }

    .p-autocomplete-item>span {
        font-size: $font-size-xs;
    }

}

.nc-combobox .combobox-dropdown,
.combobox-dropdown.on-body,
.p-multiselect-items-wrapper {
    display: flex;

    .p-autocomplete-items,
    .p-multiselect-items {
        @extend .combobox-list;

        .p-autocomplete-item,
        .p-multiselect-item {
            @extend .combobox-list-item;

            &.p-highlight {
                box-shadow: inset .5rem 0 0 0 var(--icon-bg);
            }

            &.p-focus {
                outline: 0.2rem solid var(--anchor-focus-outline);
                outline-offset: rem(-2px);
            }


        }
    }
}


.nc-combobox .combobox-dropdown {
    margin-left: -1px;
}

.p-multiselect-items-wrapper {
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    background-color: var(--dropdown-menu-bg);
    box-shadow: 0 0.5rem 1rem 0 var(--dropdown-menu-shadow-color);
    z-index: 999;

    .p-multiselect-items {
        width: rem(240px);

        .p-multiselect-item {
            .p-checkbox-box {
                background-color: $radio-checkbox-bg;
                box-shadow: 0 0 0 rem(1px) $radio-checkbox-border-color;
                border-radius: $border-radius-4;
                position: relative;
                margin-right: rem(6px);
                width: rem(16px);
                height: rem(16px);
            }

            &.p-highlight {
                box-shadow: none;

                .p-checkbox-box {
                    background-color: $radio-checkbox-checked-bg;
                    box-shadow: 0 0 0 rem(1px) $radio-checkbox-checked-border-color;

                    .p-icon-wrapper {
                        width: rem(5px);
                        height: rem(10px);

                        position: absolute;
                        top: rem(2px);
                        left: rem(6px);

                        border: solid $radio-checkbox-dot-border-color;
                        border-width: 0 rem(2px) rem(2px) 0;

                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);

                        >.p-checkbox-icon {
                            display: none;
                        }
                    }
                }

                &.p-disabled {
                    color: $radio-checkbox-disabled-text-color;
                    pointer-events: none;

                    .p-checkbox-box {
                        background-color: $radio-checkbox-checked-disabled-bg;
                        box-shadow: 0 0 0 rem(1px) $radio-checkbox-checked-disabled-border-color;

                        .p-icon-wrapper {
                            border-color: $radio-checkbox-checked-disabled-checkmark-border-color;
                        }
                    }
                }
            }

        }
    }
}

.nc-multiselect {
    >.p-multiselect {
        width: 240px;
        @extend .combobox;

        .p-multiselect-trigger {
            cursor: pointer;
            position: absolute;
            width: rem(30px);
            height: rem(30px);
            margin: 0;
            padding: 0;
            outline: none;
            border-radius: 0;
            border: rem(1px) solid transparent;
            background: transparent;
            box-shadow: none;

            top: rem(1px);
            right: rem(1px);
        }

        .p-multiselect-label {
            height: rem(24px);
            line-height: rem(24px);
        }


    }
}

.p-autocomplete-loader {
    display: none;
}

.navbar-default .nc-combobox .p-autocomplete-items {

    .p-autocomplete-item,
    .group-label {
        padding: rem(6px) rem(16px);

        >span {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
        }
    }

    .group-label {
        font-weight: 600;
        background-color: var(--table-alt-bg);
    }

}

.navbar-default .navbar-collapse .first-level.navbar-right .dropdown.open li.admin-context-option>.nc-dropdown .p-dropdown-label {
    color: var(--navigation-default-item-dropdown-open-text-color);
    background-color: var(--navigation-default-item-dropdown-open-bg);
    padding: 0;
    ;
}

.adminui-left-side {
    >.col {
        padding-left: 0;
        flex-basis: 180px;
        min-width: 180px;

        .panel {
            border: rem(1px) solid var(--table-border-color);
            box-shadow: none;
        }

        .company-panel {
            border: rem(1px) solid var(--table-border-color);
            background-color: var(--table-bg);
            font-weight: 400;
            box-shadow: none;
            height: rem(40px);
            margin: 0 0 rem(10px) 0;
            padding: rem(10px) rem(25px) rem(10px) rem(7px);

            &>a {
                font-weight: 600;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .customers-panel {
            margin: 0 0 rem(10px) 0;
            padding: 0;
        }
    }

}

/* nc table */

.table-flex-wrapper {
    border: rem(1px) solid var(--table-border-color);
    min-height: 400px;
    background-color: var(--table-bg);
}

.table-no-data {
    height: 100%;
    width: 100%;
    background-color: var(--neutral-20);
}

.table-default-role-group {
    border: rem(1px) solid var(--table-border-color);
    min-height: 200px;
}

.nc-dropdown .p-dropdown-label {
    min-width: rem(110px);
}

.nc-dropdown.action-dropdown.p-disabled .p-dropdown-label {
    border-color: transparent;
}

.nc-dropdown.action-dropdown .p-dropdown-label {
    width: rem(124px);
    max-width: rem(124px);
    border: 0;
    padding: rem(4px) rem(8px) rem(4px) rem(30px);
    box-shadow: none;
    background-color: transparent;

    &::before {
        right: rem(103px);
        background-color: var(--caret-primary-bg);
    }
}

.nc-dropdown.action-dropdown-services .p-dropdown-label {
    width: rem(165px);
    max-width: rem(165px);
    border-color: var(--button-bg);
    padding: rem(4px) rem(8px) rem(4px) rem(30px);
    box-shadow: none;

    &::before {
        right: rem(144px);
    }
}



.nc-dropdown-panel.action-dropdown-panel ul li:not(a):not(button) span {
    padding: rem(3px) rem(6px) rem(4px) rem(8px);
}

.nc-table {

    .custom-checkbox {
        font-size: rem(18px);
        font-weight: bold;
        display: grid;
        grid-template-columns: 1em auto;
        gap: 0.5em;

        input[type="checkbox"].neutral-60 {
            accent-color: var(--neutral-60);
            border-color: var(--neutral-10);
        }
    }

    .p-column-resizer-helper {
        background: var(--blue-60);
    }

    .p-scroller {
        min-height: 100%;
    }

    .p-datatable-wrapper .table {
        margin: 0;
        box-shadow: 0 0 transparent;
    }

    .table {
        font-size: $font-size-xs;
        table-layout: fixed;

        .type-of-pending-changes {
            padding-left: rem(15px);

            &>span {
                padding-right: rem(2px);
            }
        }

        .header-padding-left {
            padding-left: rem(17px);
        }

        .column-xs {
            width: rem(32px);
        }

        .column-status {
            width: rem(100px);
        }

        .column-s {
            width: rem(120px);
        }

        .column-m {
            width: rem(158px);
        }

        .column-ml {
            width: rem(178px);
        }

        .column-ml-variant {
            width: rem(200px);
        }

        .column-l {
            width: rem(220px);
        }

        .column-xl {
            width: rem(320px);
        }

        .column-xxl {
            width: rem(440px);
        }

        .color-neutral-50 {
            color: var(--neutral-50);
        }

        >thead,
        >tbody {

            tr:nth-child(odd)>td .nc-dropdown.action-dropdown-services .p-dropdown-label {
                background-color: var(--table-alt-bg);
                border: transparent;
            }

            tr.spacer {
                >td {
                    padding: 0;
                }
            }

            tr>th,
            tr>td {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-left: rem(7px);
                padding-right: rem(7px);

                .form-group {
                    margin-bottom: 0;
                    height: 32px;

                    .clear-filters-icon {
                        position: absolute;
                        top: rem(8px);
                    }
                }

                &.th-drop {
                    padding-top: rem(2px);
                    padding-bottom: rem(2px);
                }

                &:not(.column-xs):last-child {
                    padding-right: rem(27px);
                }

                &>.app-title {
                    color: var(--app-title-color);
                    font-weight: 600;
                    display: block;
                    text-decoration: none;

                    &:focus:not(:focus-visible) {
                        outline: none;
                    }
                }

                &>a.row-toggler {
                    &:focus:not(:focus-visible) {
                        outline: none;
                    }
                }

                &>a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .p-sortable-column-icon {
                    @extend .caret;
                    display: none;
                    margin-left: rem(5px);

                    &.pi-sort-amount-down,
                    &.pi-sort-amount-up-alt {
                        display: inline-block;
                    }

                    &.pi-sort-amount-up-alt {
                        transform: rotate(180deg);
                    }
                }

                &.service-child>span {
                    padding-left: rem(20px);
                }

                &.item-deleted {
                    color: var(--button-disabled-text-color);
                    text-decoration: line-through;
                }

                &.customer-info-message {
                    font-weight: 400;
                    overflow: auto;
                    text-overflow: unset;
                    background-color: var(--table-alt-bg);
                    white-space: unset;

                    >p:first-child {
                        padding-top: rem(25px);
                    }
                }
            }

            .revoke-row {
                color: var(--text-revoke);
            }

            .grant-row {
                color: var(--text-grant);
            }

            .ignore-row {
                color: var(--text-ignore);
            }

            .last-row {
                >td {
                    padding: 1px;
                    box-shadow: 0 1px var(--rows-separator) inset;
                }
            }

            .basic-row:not(:first-child) {
                >td {
                    background-color: var(--bg-default);
                    box-shadow: 0 1px var(--rows-separator) inset;
                }
            }

            .parent-row {
                .caret {
                    margin-left: rem(4px);
                    transform: rotate(-90deg);
                }

                &.parent-expanded {
                    .caret {
                        transform: rotate(0);
                    }
                }
            }

            .parent-row:not(:first-child) {
                >td {
                    background-color: var(--table-alt-bg);
                    box-shadow: 0 1px var(--rows-separator) inset;
                }
            }


            .basic-row:first-child {
                >td {
                    background-color: var(--bg-default);
                    box-shadow: none;
                }
            }

            .parent-row:first-child {

                >td {
                    background-color: var(--table-alt-bg);
                    box-shadow: none;
                }

                &.parent-expanded {
                    >td {
                        box-shadow: none;
                    }
                }
            }

            .row-expanded-header,
            .row-expanded,
            .row-expanded-header:hover {

                >th,
                td {

                    &:first-child {
                        position: relative;

                        &::before {
                            content: '';
                            background: var(--table-selected-border-color);
                            width: rem(5px);
                            height: 100%;
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }

                        &.no-green::before {
                            background: none;
                        }
                    }

                    &.column-grey {
                        background-color: var(--table-alt-bg);
                    }

                    &.column-color-neutral-50 {
                        color: var(--neutral-50);
                    }

                    &.column-color-neutral-80 {
                        color: var(--column-color-neutral-80);
                    }

                }
            }

            .row-expanded-header {
                background-color: var(--table-selected-bg);

                th {
                    white-space: nowrap;
                    font-weight: 600;
                    box-shadow: 0 1px var(--neutral-50) inset;
                }
            }

            .row-expanded-header:hover {
                background-color: var(--table-selected-bg);
            }

            .row-expanded>td {
                background-color: var(--table-bg);
            }
        }

        input[type="checkbox"],
        input[type="radio"] {
            height: auto;
        }

        .filters-row {

            th:not(.column-xs):last-child {
                padding-right: rem(27px);
            }

            .search-group input.form-control[type=text],
            .search-group input.form-control[type=search] {
                padding-right: rem(24px);
                margin-left: rem(6px);
                border: rem(1px) solid var(--green-50);
            }

            .search-group {
                .clear-search {
                    right: rem(10px);
                }

                &.filter-group {
                    .clear-search {
                        right: rem(30px);
                    }
                }
            }


            .date-filter {
                display: flex;
                flex-wrap: nowrap;

                .col {
                    padding: 0;
                    flex-shrink: 1;

                    &+.col {
                        margin-left: rem(2px);
                    }
                }

                .nc-dropdown {
                    width: 100%;

                    .p-dropdown-label {
                        padding: 0.3rem 1.6rem 0.4rem 0.4rem;
                        min-width: 1%;

                        &::before {
                            right: 0.4rem;
                        }
                    }
                }

                .nc-dropdown.dropdown-date-filter {
                    width: 100%;
                }
            }
        }

        &.table-hover {
            >thead {
                >tr {

                    >th,
                    >td {
                        &:hover {
                            background-color: transparent;
                            cursor: default;
                        }
                    }
                }
            }

            >tbody {
                >tr {
                    td {
                        padding-top: rem(5px);
                    }

                    &:hover>th,
                    &:hover>td {
                        cursor: default;
                    }
                }
            }
        }
    }

    .table-header {
        background: var(--table-bg);
        border-bottom: 0.1rem solid var(--table-border-color);
        height: rem(40px);
        margin: 0;
        padding: rem(11px) rem(250px) rem(11px) rem(7px);
        font-size: $font-size-xs;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        >.nc-multiselect {
            position: absolute;
            top: rem(3px);
            right: rem(6px);
        }
    }

    .table-summary {
        background: var(--table-bg);
        margin-right: 0;

        >p {
            font-weight: normal;
            margin: 0;
            padding: rem(6px);
            border-top: rem(1px) solid var(--table-border-color);
            height: rem(32px) !important;
            font-size: $font-size-xs;
        }
    }
}

.adminui-left-side .panel.sidebar-panel {
    .panel-heading {
        padding: 0;
    }

    .panel-body {
        padding: 0;
    }

    .nc-dropdown,
    .nc-dropdown-label {
        width: 100%;
    }

    .cdk-virtual-scroll-orientation-vertical,
    .no-virtual-scroll .p-datatable-wrapper {
        overflow-y: scroll;
    }

    .table-flex-wrapper {
        border: 0 none;
        min-height: rem(300px);
    }

    .table {

        // table-layout: auto;
        &>thead>tr {
            &.customer-link-bg {
                background-color: var(--table-alt-bg);
            }

            >td,
            >th {
                border: 0 none;

                .form-group {
                    margin-bottom: 0;

                    &.search-group {
                        padding-right: rem(24px);

                        .filters-icon {
                            position: absolute;
                            top: rem(8px);
                            right: 0;

                            >.vismaicon-sm {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            th:not(.column-xs):last-child {
                padding: rem(6px) rem(8px) rem(6px) 0;

                .search-group {
                    .form-control {
                        font-size: $font-size-xs;
                    }
                }
            }

            &.action-row {
                th:last-child {
                    padding: 0.6em 0.8rem;
                }

                a {
                    display: inline-block;
                    height: rem(26px);
                    line-height: rem(24px);
                    padding-right: rem(2px);
                    text-decoration: none;
                    font-weight: normal;

                    &.customer-link {
                        margin-right: rem(5px);
                        font-weight: 600;
                        text-decoration: none;
                    }

                    &.customer-link:hover {
                        margin-right: rem(5px);
                        text-decoration: none;
                    }
                }
            }
        }

        &>thead>tr {
            &.row-separator {
                height: 3px;

                th,
                th:last-child {
                    padding: 0;
                }
            }

            &.selected-item-action {
                background-color: var(--table-alt-bg);
                box-shadow: 0 1px var(--rows-separator) inset;

                th,
                th:last-child {
                    padding-right: 0;
                }
            }

            &.selected-item {
                background-color: var(--table-alt-bg);

                .selected-item-child:first-child,
                .selected-item-child:last-child {
                    font-weight: 400;
                    padding-left: rem(10px);
                }
            }

            &.selected-item-last {
                background-color: var(--table-alt-bg);
                box-shadow: 0 -1px var(--rows-separator) inset;
            }
        }

        &>tbody>tr {

            &.item-deleted>td {
                cursor: default;
            }

            >td,
            >th {
                border: 0 none;
                background-image: none;
                padding: rem(2px) rem(4px) rem(4px) rem(10px);
                cursor: pointer;
            }

            >td.float-right {
                margin-right: rem(6px);
            }

            >td.column-s {
                cursor: default;
            }

            >td.column-xs,
            th.column-xs {
                padding: 0.3rem 0.6rem 0.5rem 1.0rem;
            }

            &:nth-child(2n):not(.header-row)>th,
            &:nth-child(2n):not(.header-row)>td {
                background-color: var(--table-bg);
            }

        }
    }

    .table>tbody tr.header-row {
        background-color: var(--table-alt-bg);
        box-shadow: 0 1px var(--rows-separator) inset;

        input[type="checkbox"] {
            accent-color: var(--accent-color);
            border-color: var(--accent-color);
        }

        .caret-service {
            margin-left: rem(5px);
            margin-right: rem(5px);
        }
    }

    .table>tbody>tr.empty-row {

        >td,
        >th {
            cursor: default;
        }
    }

    .nc-table .table-summary>p {
        height: rem(24px);
        padding: rem(6px) 0 rem(6px) rem(6px);

        .clear-selections {
            margin-right: rem(1px);

            >.vismaicon {
                margin: 0;
            }

            &:focus {
                outline-offset: rem(3px);
            }
        }

        .clear-selections-only {
            margin-right: rem(8px);

            >.vismaicon {
                margin: 0;
            }

            &:focus {
                outline-offset: rem(3px);
            }
        }

        .panel-actions {
            float: right;
            margin: 0 rem(2px) 0 0;

            .dropdown-toggle {
                &::before {
                    display: none;
                }

                .vismaicon:not(.vismaicon-filled)::before {
                    background-color: var(--anchor-color);
                }
            }

            &.open {
                .dropdown-toggle {
                    background-color: transparent;

                    .vismaicon:not(.vismaicon-filled)::before {
                        background-color: var(--anchor-color);
                    }
                }

                .dropdown-menu {
                    box-shadow: 0 rem(2px) rem(4px) 0 var(--dropdown-menu-shadow-color), 0 rem(-2px) rem(4px) 0 var(--dropdown-menu-shadow-color);
                    right: rem(-14px);
                    bottom: 100%;
                    width: rem(200);
                }
            }

        }
    }

}

/* nc-dropdown */

.nc-dropdown {
    .p-hidden-accessible {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .p-dropdown-label {
        @extend .btn;
        @extend .dropdown-toggle;
        font-size: $font-size-xs;
    }

    .nc-dropdown-panel {
        ul {
            @extend .dropdown-menu;
            display: block;
            top: calc(100% + 0.1rem);

            li {
                @extend .dropdown-item;
                font-size: $font-size-xs;

                &.p-focus {
                    outline: 0;
                    border-color: var(--input-focus-border-color);
                    box-shadow: inset 0 0 0 0.1rem var(--input-focus-border-color), 0 0.2rem 0.4rem 0 var(--input-hover-shadow-color);
                }
            }
        }
    }

    &.p-disabled,
    &.nc-dropdown-primary.p-disabled {
        .p-dropdown-label {
            color: var(--button-disabled-text-color);
            border-color: var(--button-disabled-border-color);
            background-color: var(--button-disabled-bg);
            box-shadow: none;
            cursor: default;
        }

        .p-dropdown-label::before {
            background-color: var(--icon-disabled-bg);
        }
    }

    &.nc-dropdown-primary {
        .p-dropdown-label {
            @extend .btn-primary;
        }
    }

    &.p-dropdown-open {
        .p-dropdown-label {
            outline: 0;
            border-color: var(--input-focus-border-color);
            box-shadow: inset 0 0 0 0.1rem var(--input-focus-border-color), 0 0.2rem 0.4rem 0 var(--input-hover-shadow-color);
        }
    }

    &.nc-dropdown-wide {
        .p-dropdown-label {
            min-width: rem(300px);
        }
    }

    &.p-focus {
        .p-dropdown-label {
            outline: 0;
            border-color: var(--input-focus-border-color);
            box-shadow: inset 0 0 0 0.1rem var(--input-focus-border-color), 0 0.2rem 0.4rem 0 var(--input-hover-shadow-color);
        }
    }
}

.nc-dropdown.language-dropdown {
    width: rem(230px);
}


.nc-dropdown-panel {
    ul {
        @extend .dropdown-menu;
        display: block;
        padding: rem(12px);
        top: 100%;
        max-height: rem(300px);
        overflow-y: auto;

        li {
            @extend .dropdown-item;
            font-size: $font-size-xs;

            &:not(a):not(button) span {
                padding: rem(3px) rem(16px) rem(4px);
            }

            &:hover {
                background-color: var(--dropdown-menu-hover-bg);
            }

            &.p-highlight {
                color: var(--dropdown-menu-selected-text-color);
            }

            &.p-focus {
                outline: 0;
                border-color: var(--input-focus-border-color);
                box-shadow: inset 0 0 0 0.1rem var(--input-focus-border-color), 0 0.2rem 0.4rem 0 var(--input-hover-shadow-color);
            }
        }
    }

    &.date-filter-panel,
    &.action-panel {
        ul {
            padding: rem(12px) rem(4px);
            max-height: rem(220px);

            li {
                &:not(a):not(button) span {
                    padding: rem(3px) rem(2px) rem(4px);
                }
            }
        }
    }

    &.action-panel {
        ul {
            padding: rem(12px) rem(4px);
            max-height: rem(120px);

            li {
                &:not(a):not(button) span {
                    padding: rem(3px) rem(2px) rem(4px);
                }
            }
        }
    }
}

.caret-left {
    transform: rotateZ(90deg);
}

.caret-right {
    transform: rotateZ(270deg);
}

.admin-loading-widget {
    background: var(--bg-default);
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;

    >img {
        margin: -32px 0px 0px -32px;
        position: absolute;
        top: 50%;
        left: 50%;
    }
}

.tab-header,
.p-tabview-nav {
    flex-wrap: nowrap;
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    border: 1px solid var(--table-border-color);

    >li {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        background-color: var(--table-alt-bg);
        border-left: 1px solid var(--table-border-color);

        .p-tabview-nav-link {
            font-size: $font-size-xs;
            color: var(--body-font-color);
            display: block;
            padding: rem(8px) rem(10px) rem(8px);
            text-decoration: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: rem(2px);

            &:focus {
                outline-offset: rem(-2px);
            }

            &:focus:not(:focus-visible) {
                outline: none;
            }
        }

        &.p-highlight {
            background-color: var(--module-container-bg-color);
            box-shadow: 0 2px 0 var(--module-container-bg-color);
            border-left: 5px solid var(--icon-bg);
            flex-basis: auto;
            flex-shrink: 0;
        }
    }

    &.tab-header-sidebar {
        border-bottom: 1px solid var(--table-border-color);

        >a {
            background-color: var(--table-alt-bg);
            border-left: 1px solid var(--table-border-color);
            color: var(--body-font-color);
            display: inline-block;
            padding: rem(8px) rem(10px) rem(6px);
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            text-decoration: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: top;

            &:focus,
            &:focus-visible {
                outline: 0.2rem solid var(--anchor-focus-outline);
                outline-offset: rem(-8px);

                &:not(:focus-visible) {
                    outline: none;
                }
            }

            &.active {
                background-color: var(--module-container-bg-color);
                box-shadow: 0 2px 0 var(--module-container-bg-color);
                border-left: 5px solid var(--icon-bg);
                color: var(--body-font-color);
                flex-basis: auto;
                flex-shrink: 0;
            }

        }
    }
}


@media (min-width: 1280px) {
    .modal-dialog.modal-xl.entity-details-dialog {
        width: 140rem;
    }

    .modal-dialog.modal-xs.entity-details-dialog {
        width: 40rem;
    }
}

.bottom-border {
    border-bottom: 1px solid var(--neutral-60);
}

.modal-dialog {
    width: rem(750px);

    .modal-title {
        font-weight: 600;
        margin-bottom: rem(20px);
        font-size: rem(18px);
    }


    &.nc-modal {

        padding: 0;
        min-height: rem(322px);

        .alert.admin-alert {
            top: auto;
        }

        .modal-header {
            padding: rem(12px) rem(20px) rem(10px);
            height: rem(46px);
        }

        .tab-panel,
        .dialog-header-tabs {
            .table-flex-wrapper {
                border-top: 0;
            }

            li:nth-last-child(2) {
                .p-tabview-nav-link {
                    border-right: 0 none;
                }
            }

            .tab-header>li>a {
                padding: rem(7px) rem(20px) rem(5px);
            }

            li:first-child {
                .p-tabview-nav-link {
                    padding-left: rem(8px);
                }
            }
        }

        .dialog-header-tabs {
            height: rem(36px);
            padding: 0;
            border-top: 1px solid var(--neutral-60);
            border-bottom: 1px solid var(--neutral-60);
            box-shadow: var(--shadow-10);

            .text-disabled {
                color: var(--text-tab-disabled) !important;
            }

            >.nav-tabs:not(.navbar-nav).nav-tabs-primary .nav-item a::before {
                content: "";
                position: absolute;
                width: calc(100% - 3.2rem);
                height: 0.4rem;
                top: calc(7.8rem);
                left: 1.6rem;
                background: var(--icon-bg);
                top: auto;
                bottom: 0;
            }

            >.nav-tabs.nav-tabs-primary {
                height: rem(32px);
                background-color: var(--tabs-content-bg);
                border-radius: 0;
                border-bottom: none;
                padding-left: 1.6rem;
                top: 0;
                width: 100%;

                >.nav-item {

                    >a {
                        background-image: none;
                        background-image: unset;
                        line-height: 1;
                        display: flex;
                        position: relative;
                        align-items: center;
                        height: rem(22px);
                        padding: 1.6rem;
                        justify-content: center;
                        border-bottom: 0;
                        text-align: center;
                        text-decoration: none;
                        color: var(--tabs-text-color);
                        outline: 0;
                        font-size: $font-size-base;
                    }

                    &.active {
                        >a:focus:not(:focus-visible)::after {
                            border: 0 none;
                        }
                    }
                }
            }
        }

        .dialog-content {
            padding: rem(12px) rem(24px) 0;
            height: rem(500px);
            max-height: calc(100vh - 215px);
            min-height: rem(180px);
            position: relative;
            overflow-x: auto;

            .nc-table .text-disabled>td:last-child>a {
                display: none;
            }

            >.form-horizontal,
            >.row {
                margin-top: rem(10px);

                .tooltip {
                    min-width: rem(300px);

                    .close {
                        position: absolute;
                        top: rem(3px);
                        right: rem(3px);
                    }
                }

                .tooltip.bottom,
                .tooltip[data-popper-placement=bottom] {
                    inset: calc(100% + rem(10px)) auto auto calc(50% - rem(150px)) !important;
                }

                .form-group .vismaicon-info {
                    margin-left: rem(8px);
                    top: rem(-2px);
                    vertical-align: middle;
                }

                .has-error.has-warning input.form-control {
                    border-color: var(--tooltip-border-color);
                    color: var(--text-warning);
                }
            }

            >.row.margin-top-0 {
                margin-top: 0;
            }

            >.row.margin-top-12 {
                margin-top: rem(12px);
            }

            .form-group.description>.form-control {
                min-height: rem(80px);
                padding: 0.3rem 1.6rem 0.4rem;
                line-height: 1.7;
                color: var(--body-font-color);
                border: 0.1rem solid var(--input-border-color);
                background-color: var(--input-disabled-bg);
                box-shadow: none;

                ul li {
                    font-size: $font-size-xs;
                }
            }

            .table-heading {
                font-size: $font-size-xs;
                margin-bottom: 0;
                padding: rem(7px) 0 rem(8px) rem(8px);
            }

            .form-group.form-group-thin {
                margin-bottom: rem(4px);
            }

            .thin-content {
                border: rem(1px) solid var(--table-border-color);
                border-top: 0;
                position: relative;

                .form-horizontal {
                    margin: 0 rem(22px) rem(16px) rem(16px);
                    padding-top: rem(16px);

                    .form-group.form-group-thin {
                        margin-bottom: rem(4px);
                    }

                    .tooltip {
                        min-width: rem(300px);

                        .close {
                            position: absolute;
                            top: rem(3px);
                            right: rem(3px);
                        }
                    }

                    .tooltip.bottom,
                    .tooltip[data-popper-placement=bottom] {
                        inset: calc(100% + rem(10px)) auto auto calc(50% - rem(150px)) !important;
                    }

                    .new-user-buttons {
                        position: absolute;
                        right: rem(20px);
                        bottom: 0;
                    }
                }

            }

        }

        .dialog-footer {
            border-top: 1px solid var(--neutral-60);
            padding: rem(12px) rem(20px);
            height: rem(57px);

            .btn {
                margin-bottom: 0;
            }

            .delete-company-wrapper {
                position: relative;
                width: 30%;

                .vismaicon-info {
                    top: rem(6px);
                    left: rem(6px);

                    &:hover+.tooltip {
                        display: block;
                        top: rem(-80px);
                        left: rem(-20px);
                    }
                }
            }
        }

        .discard-mask {
            background-color: var(--modal-overlay-bg);
            border-radius: rem(24px);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 6999;

            .discard-dialog {
                position: relative;
                width: auto;
                height: auto;
                background: transparent;
                overflow: visible;

                .modal-header {
                    padding: 0;
                }

                .text-highlighted {
                    font-weight: 600;
                }
            }
        }
    }

    &.advanced-filters {
        padding-top: rem(40px);

        .modal-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            clear: both;
            width: 100%;

            .modal-body {
                overflow: visible;
            }
        }
    }
}

.alert.admin-alert,
.alert.termination-alert {
    position: absolute;
    right: 0;
    left: 0;
    border-radius: 0;
    z-index: 2;

    &.alert-sm {
        padding-top: rem(10px);
        padding-bottom: rem(10px);

        &::before,
        .close {
            top: rem(11px);
        }
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.alert.admin-alert {
    top: rem(64px);
}

.alert.termination-alert {
    top: 0;
}

.alert.toast-alert {
    @extend .toast;

    width: rem(450px);
    padding: 2.6rem 5rem;
    position: -webkit-sticky;
    position: fixed;
    top: auto;

    left: auto;
    z-index: 2001;

    &::before {
        top: calc(50% - 8px);
    }

    .close {
        top: rem(17px);
    }
}

.alert.toast-alert.toast-main {
    right: rem(20px);
    bottom: rem(120px);
}

.alert.toast-alert.toast-dialog {
    right: rem(20px);
    bottom: rem(45px);
}


.search-group .clear-search {
    background-color: transparent;
}

.tooltip.p-tooltip-right {
    margin-left: rem(10px);
}

.alert-msg-text {
    >p {
        margin-bottom: rem(10px);
    }

    &.scrollable {
        max-height: rem(130px);
        overflow-y: auto;
    }
}

.company-status-flow {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    list-style: none;
    margin-bottom: 0;

    li {
        flex: 0;
        display: inline-block;
        position: relative;

        .vismaicon {
            position: absolute;
            top: rem(7px);
            right: rem(4px);
            z-index: 1;

            &.background-white::before {
                background-color: var(--white);
            }
        }
    }

    .spacer {
        flex: 1;
        height: rem(2px);
        margin-top: rem(15px);
        background-color: var(--stepper-bg);
    }

    .btn.company-status:disabled {
        color: var(--stepper-active-bg);
        border-color: var(--stepper-active-border-color);
        background-color: var(--stepper-active-border-color);
        box-shadow: 0 .5rem 1rem 0 var(--stepper-active-shadow-color);
    }

}

.alert.alert-sm.alert-status-terminated {
    min-height: rem(25px);
}

.tooltip.left.delete-tooltip {
    transform: translate(-10px, 0px);
    text-align: left;
}

div.row div.terminate-subscriber .vismaicon-info {
    position: relative;
    top: 0.2rem;
    right: -12.4rem;
    z-index: 1;
}

.drawer.help-drawer {
    .drawer-header {
        p.help-info {
            font-size: rem(16px);
        }
    }

    .drawer-body {
        padding: 0 rem(48px) 0;
        margin: rem(16px) 0 rem(16px);
        height: calc(100% - 36.5rem);
    }

    h2 {
        font-size: rem(18px);
    }

    .collapse-list {
        .collapse-list-header .collapse-list-title {
            position: relative;
            padding-left: rem(28px);
            width: 100%;
            font-size: 1.8rem;

            .thumbnail {
                display: block;
                margin-top: rem(6px);
            }

            .caret {
                margin-left: 0;
                position: absolute;
                top: rem(24px);
                left: rem(4px);
            }
        }

        &[open] {
            .collapse-list-header .collapse-list-title {
                .thumbnail {
                    display: none;
                }
            }
        }
    }
}

.p-dropdown-trigger-icon {
    display: none;
}

.p-overlaypanel {
    transform: none !important;
    width: 75rem;

    .p-dialog-mask.modal {
        border-radius: 2.4rem;
        display: flex;
        opacity: 1;
        width: auto;
        height: auto;
        bottom: 0;
        right: 0;
    }

    .modal-dialog {
        max-width: none;
    }
}

// START sorticon
sortalticon {
    display: none;
}

sortamountupalticon::before {
    content: url(assets/images/vismaicons/arrow_large_up.svg);
}

sortamountdownicon::before {
    content: url(assets/images/vismaicons/arrow_large_down.svg);
}

sortamountupalticon,
sortamountdownicon {
    svg {
        display: none;
    }
}

sortamountupalticon::before,
sortamountdownicon::before {
    font-size: rem(12px);
    float: right;
    color: var(--anchor-color);
}

// END sort icon

.list-group {
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: var(--body-font-color);
    list-style: none;
    border-radius: 0;
    background-color: var(--table-bg);
    box-shadow: none;

    li.list-group-item {

        cursor: default;
        margin: 0;
        padding: 0;
        border-top: 1px solid var(--table-border-color);
        vertical-align: middle;

        a.menu-link {
            margin: 0px;
            display: block;
            padding: rem(10px) 0 rem(10px) rem(15px);
            text-decoration: none;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.active {
                color: var(--neutral-80);
                padding-left: rem(10px);
                font-weight: 600;
                background-color: var(--module-container-bg-color);
                box-shadow: 0 2px 0 var(--module-container-bg-color);
                border-left: 5px solid var(--icon-bg);
                color: var(--body-font-color);
                flex-basis: auto;
                flex-shrink: 0;
            }

            &:focus:not(:focus-visible) {
                outline: none;
                outline-offset: 0;
            }
        }
    }

    li.list-group-item.first-group-item {
        border-top: 0;
    }
}

.rotate-180 {
    transform: rotate(180deg);
}

a>.vismaicon.margin-right-0 {
    margin-right: 0;
}

.padding-top-7 {
    padding-top: rem(7px);
}

p a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.pending-changes-info-box {
    height: 462px;
    line-height: 462px;
    text-align: center;
    font-weight: 600;
    border: 1px solid var(--table-border-color);
    background-color: var(--table-alt-bg);
}

textarea.form-control.read-only[readonly] {
    border: 1px solid var(--input-border-color);
}

.table.support-users {
    >tbody>tr.header-row {
        background-color: var(--table-alt-bg);
        box-shadow: 0 1px var(--rows-separator) inset;
    }

    >thead>tr.action-row a {
        display: inline-block;
        height: 2.6rem;
        line-height: 2.4rem;
        padding-right: 0.2rem;
        text-decoration: none;
        font-weight: normal;
    }
}

// Calendar
.nc-calendar {

    &.p-inputwrapper {
        color: var(--input-text-color);
        border: 0.1rem solid var(--input-border-color);
        background-color: var(--input-bg);
        width: rem(230px);
    }

    .p-datepicker-trigger.p-button-icon-only {
        background: none;
        border: none;

        .p-icon-wrapper {
            display: inline-block;
            width: rem(16px);
            height: rem(16px);

            .p-icon {
                display: none;
            }
        }

        .p-icon-wrapper::before {
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            background-image: url(assets/images/vismaicons/datepicker.svg);
            background-repeat: no-repeat;
        }
    }

    .p-datepicker {
        margin-left: rem(-1px);

        .p-datepicker-group-container {
            margin-top: rem(1px);

            .p-datepicker-header {
                margin-bottom: rem(6px);

                .p-datepicker-month,
                .p-datepicker-year {
                    background: none;
                    border: none;
                    padding: rem(4px);
                }

                .p-datepicker-month {
                    margin-right: rem(2px);
                }

                .p-datepicker-year {
                    margin-left: rem(2px);
                }

                .p-datepicker-prev,
                .p-datepicker-next {
                    background: none;
                    border: none;

                    .p-icon-wrapper {
                        color: var(--icon-bg);
                    }
                }
            }

            .p-datepicker-calendar {
                thead>tr {
                    th {
                        padding: rem(4px) rem(4px);
                    }
                }

                tbody>tr {
                    td {
                        padding: rem(4px) rem(4px);

                        .p-disabled {
                            color: var(--input-disabled-text-color);
                        }
                    }
                }
            }
        }

        .p-datepicker-group-container,
        .p-monthpicker,
        .p-yearpicker {
            background-color: var(--dropdown-menu-bg);
            box-shadow: 0 .5rem 1rem 0 var(--dropdown-menu-shadow-color);
            width: rem(230px);
            padding: rem(10px);
        }

        .p-monthpicker {
            .p-monthpicker-month {
                padding: rem(4px) rem(4px);
            }
        }

        .p-yearpicker {
            .p-yearpicker-year {
                padding: rem(4px) rem(4px);
            }
        }
    }
}

.company-status-dialog {
    .modal-dialog {
        width: rem(1010px);
        height: rem(530px);
    }

    .modal-body {
        font-size: rem(14px);
    }

    ul li {
        margin-bottom: rem(7px);
    }
}

.dialog-footer .remove-user-div .vismaicon-info {
    position: relative;
    top: 0.5rem;
    right: 2.7rem;
    z-index: 1;
}

.vismaicon-remove-circle.action-revoke-icon {
    filter: invert(43%) sepia(97%) saturate(1509%) hue-rotate(16deg) brightness(94%) contrast(101%);
}

.vismaicon-add-circle.action-grant-icon {
    filter: invert(57%) sepia(13%) saturate(1594%) hue-rotate(74deg) brightness(90%) contrast(87%);
}

.div-readonly {
    color: var(--body-font-color);
    border: 0.1rem solid var(--input-border-color);
}

.button-terminated {
    margin-top: rem(4px);
    pointer-events: none;
    background-color: var(--blue-70);
    border-color: var(--blue-70);
    color: var(--white);
}

ul.company-groups-list {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    list-style-type: square;
    list-style-position: inside;

    li {
        font-size: small;
        padding-right: rem(25px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.discard-dialog .modal-body {
    margin-bottom: rem(30px);
}

.selected-disabled {
    color: var(--text-tab-disabled) !important;
}

.action-button {
    height: rem(32px);
    width: rem(124px);
    max-width: rem(124px);
    border: 0;
    padding: rem(4px) rem(8px) rem(4px) rem(30px);
    box-shadow: none;
    background-color: transparent;
    text-align: left;
    cursor: pointer;
    position: relative;

    span {
        margin-top: rem(-1px);
        margin-left: rem(-20px);
        position: absolute;
    }
}

.discard-dialog .modal-body {
    font-size: 1.4rem;
}

.vismaicon.vertical-middle {
    vertical-align: middle;
    margin-right: rem(7px);
}